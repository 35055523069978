import React from 'react';

import Page from './Components/page'; // Import the home page component
import './Components/globals.css';

function App() {
  return (
    <div className="antialiased">
    
      <Page /> {/* This will be your home page */}
     
    </div>
  );
}

export default App;